<template>
  <div>
    <FormStep :step="2" :validate="validate" />
    <PriceDetails v-if="reservationPanel" :vehicle="selectedVehicle" />
    <div class="mb-4 book-vehicle--title">
      <Label class="reserve--header" size="medium" color="primary">{{
        $t("panels.reserve.steps.2.title")
      }}</Label>
    </div>
    <div class="mt-4">
      <CpInput
        ref="name"
        :disabled="!isGuest"
        :label="$t('placeholder.name') + ' *'"
        :value="getUserInfoDifferentKeys.name"
        :validator="validateName"
      />
    </div>
    <div class="mt-4">
      <CpInput
        ref="email"
        :disabled="!isGuest"
        :label="$t('placeholder.email') + ' *'"
        :value="getUserInfoDifferentKeys.email"
        :validator="validateEmail"
      />
    </div>
    <div class="mt-4">
      <CpInput
        ref="phone"
        :label="$t('placeholder.phone') + ' *'"
        :value="getPhoneContact"
        :validator="validatePhone"
      />
    </div>
    <div class="mt-4" v-if="!hideBirthdate">
      <CpDatePicker
        ref="birthdate"
        :label="$t('placeholder.date')"
        :value="formatFormBirthdate"
        @onChange="handleBirthday"
      />
    </div>
    <div class="mt-4" v-if="showConcession">
      <ConcessionPicker ref="dealer" @onDealer="onDealer" />
    </div>
    <div class="mt-4" v-if="isGuest">
      <Label size="small" color="tertiary">
        {{ $t("validators.requiredAccount") }}
        <span @click="triggerLogin" class="login-span">{{
          $t("validators.requiredAccountLogin")
        }}</span
        >.
      </Label>
    </div>
    <div class="mt-4" v-if="isGuest">
      <CpPassword
        ref="password"
        @onValidation="handleOnValidation"
        :disabled="!isGuest"
      />
    </div>
    <div class="privacy-policy--wrapper" v-if="isGuest">
      <PrivacyPolicy
        class="mb-4"
        privacyPolicyLinkURL="/politica-de-privacidade"
        @acceptedPrivacyPolicy="onPrivacyPolicy"
        @acceptedEmailMarketing="onMarketing"
        @onClickPolicyLink="onOpenPrivacyPolicy"
        :textMarketing="$t('privacyPolicy.textMarketing')"
      />
      <Label
        v-if="errs.privacy"
        color="tertiary"
        bold="thin"
        size="small"
        class="validate-message"
      >
        {{ errs.privacy }}</Label
      >
    </div>
  </div>
</template>

<script>
import { FormStep } from "../../../molecules";
import {
  ConcessionPicker,
  PriceDetails,
  PrivacyPolicy
} from "../../../organisms";
import { Label } from "../../../atoms";
// import v from "../../../../utils/validator";
import { mapGetters } from "vuex";
import { EventBus } from "../../../../utils/eventbus.js";
import CpPassword from "../../../refactor/CpPassword.vue";
import CpInput from "../../../refactor/CpInput.vue";
import CpDatePicker from "../../../refactor/CpDatePicker.vue";
import LABEL_TYPE from "../../../../utils/labelType";
import dayjs from "dayjs";
import formValidator from "../../../../utils/formValidator";
import { FOOTPRINT_TYPES, registerEvent } from "../../../../api/footprint";

const PRIVACY_POLICY_URL = "https://www.hyundai.pt/politica-de-privacidade/";
const TERMS_OF_USE_URL = "https://www.hyundai.pt/cookies-no-website-hyundai/";
const PRIVACY_POLICY_URL_CLICK_TO_BUY = "/politica-de-privacidade/";

export default {
  name: "PersonalFormStep",
  components: {
    FormStep,
    PriceDetails,
    Label,
    PrivacyPolicy,
    CpPassword,
    CpInput,
    CpDatePicker,
    ConcessionPicker
  },
  data() {
    return {
      validPass: false,
      errs: {},
      form: {
        ...this.getUserInfoDifferentKeys,
        privacy: false,
        marketing: false
      },
      showInfoMessage: false
    };
  },
  props: {
    selectedVehicle: {
      type: Object
    },
    reservationPanel: {
      type: Boolean,
      default: true
    },
    hideBirthdate: {
      type: Boolean,
      default: false
    },
    showConcession: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleBirthday(value) {
      this.form.birthdate = value;
    },
    handlePhone(value) {
      this.form.phone = value;
      this.getUserInfoDifferentKeys.phone = value;
    },
    onDealer(d) {
      this.form.dealer = d;
    },
    validatePhone(e) {
      const re = /^(\+?351|(00)?351)?\s?9[2,6,3,1]\d\s?\d{3}\s?\d{3}$/;
      if (!re.test(e)) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.phone")];
      }
      this.form.phone = e;
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateEmail(e) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(e)) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.email")];
      }
      this.form.email = e;
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateName(e) {
      if (!e) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.name")];
      }
      this.form.name = e;
      return [LABEL_TYPE.SUCCESS, ""];
    },
    handleOnValidation([pass, isValid]) {
      this.form.pass = pass;
      this.validPass = isValid;
    },
    triggerLogin() {
      this.$store.state.isOpenSigninPopup = true;
    },
    onFocusHandler() {
      this.showInfoMessage = true;
    },
    onBlurHandler() {
      this.showInfoMessage = false;
    },
    validate() {
      const refs = [
        {
          ref: "name",
          value: this.form.name || this.getUserInfoDifferentKeys.name
        },
        {
          ref: "email",
          value: this.form.email || this.getUserInfoDifferentKeys.email
        },
        {
          ref: "phone",
          value: this.form.phone || this.getUserInfoDifferentKeys.phone
        },
        {
          ref: "birthdate",
          nested: true,
          value: this.form.birthdate || this.getUserInfoDifferentKeys.birthdate
        },
        {
          ref: "password",
          value: this.form.pass
        }
      ];

      this.hideBirthdate ? refs.splice(3, 1) : refs;

      const errs = formValidator(
        this.isGuest ? refs : refs.slice(0, -1),
        this.$refs
      );
      if (errs.length > 0 || (this.isGuest && !this.form.privacy)) {
        return false;
      }

      const {
        email,
        name,
        pass,
        phone,
        birthdate,
        marketing,
        dealer
      } = this.form;
      if (this.isGuest) {
        this.triggerRegister({
          email,
          name,
          password: pass,
          phoneNumber: phone,
          birthdate,
          emailMarketing: !!marketing,
          dealerId: dealer?.dealerCode
        });
        return false;
      }

      // Update User Data in Store and Call Backend
      this.$store.dispatch("updateUser", {
        phoneNumber: phone,
        userEmail: email,
        name,
        ...(birthdate && { birthdate })
      });

      this.$store.commit("setUser", this.form);

      if (!this.reservationPanel) {
        const result = {
          email: this.form?.email,
          name: this.form?.name,
          phone: this.form?.phone
        };
        registerEvent(FOOTPRINT_TYPES.RETAKE_STEP_2, result);
        this.requestValuation();
      } else {
        const result = {
          infoForm: {
            email: this.form?.email,
            name: this.form?.name,
            phone: this.form?.phone,
            birthdate: this.form?.birthdate
          },
          vin: this.getSelectedVehicle?.vin
        };
        registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_2, result);
      }

      return this.reservationPanel;
    },
    async triggerRegister(payload) {
      const success = await this.$store.dispatch("storeRegisterToken", payload);

      if ((success && !this.reservationPanel) || this.getUserStatus === 1) {
        return this.requestValuation();
      }

      if (success && this.getUserStatus === 1) {
        EventBus.$emit("valid", 2);
      }
    },
    requestValuationFootprint(result) {
      const payload = {
        ...result
      };
      registerEvent(FOOTPRINT_TYPES.RETAKE_EVAL, payload);
    },
    requestValuation() {
      if (this.getUserStatus === 1) {
        const { email, name, phone } = this.form;
        const userInfo = {
          name: name,
          email: email,
          phoneNumber: phone,
          dealerId: this.form.dealer.dealerCode
        };
        this.$store.commit("spinnerModule/setLoading", true);

        this.$store
          .dispatch("storeValuation", userInfo)
          .then(result => {
            result;
            EventBus.$emit("valid", 2);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eec.retomaQ1",
              model: this.getModell
            });
            this.$emit("onSubmit");
            this.requestValuationFootprint(result);
            this.$store.dispatch("storeGetAllReturns");
            this.$store.commit("spinnerModule/setLoading", false);
          })
          .catch(error => {
            error;
            this.requestValuationFootprint(error);
            this.$store.commit("spinnerModule/setLoading", false);
          });
      }
    },

    termsOfUse() {
      window.open(TERMS_OF_USE_URL, "_blank");
    },
    privacyPolicy() {
      window.open(PRIVACY_POLICY_URL, "_blank");
    },
    onOpenPrivacyPolicy() {
      window.open(PRIVACY_POLICY_URL_CLICK_TO_BUY, "_blank");
    },
    onPrivacyPolicy(e) {
      this.form.privacy = e;
    },
    onMarketing(e) {
      this.form.marketing = e;
    }
  },
  computed: {
    ...mapGetters([
      "getUser",
      "isGuest",
      "getUserName",
      "getUserInfo",
      "getUserInfoDifferentKeys",
      "getUserPhoneNumber",
      "getUserEmail",
      "getFormattedBirthdate",
      "getUserStatus",
      "getModell",
      "getSelectedVehicle"
    ]),
    formatFormBirthdate() {
      const date =
        this.form.birthdate || this.getUserInfoDifferentKeys?.birthdate;
      dayjs;
      return date;
      // if (Date.parse(date)) {
      //   return date;
      // }
      // return dayjs(date).format("DD/MM/YYYY");
    },
    getPhoneContact() {
      return this.form.phone || this.getUserInfoDifferentKeys?.phone;
    }
  },
  mounted() {
    const formDealer = this.form?.dealer;
    this.form = {
      ...this.getUserInfoDifferentKeys,
      dealer: formDealer
    };
    this.errs = {};
  }
};
</script>

<style scoped src="./styles.css">
:deep(.panel) .panel--content {
  overflow: auto;
  background-color: red;
}
</style>
